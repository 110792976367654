import { render as toPlainText } from 'datocms-structured-text-to-plain-text';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image, { ImageData } from 'quarks/Image';
import Link from 'quarks/Link';
import Text from 'quarks/Text';

import type { DatoCmsTemplateCaseStudy } from 'graphqlTypes';
import type { FC } from 'react';

type HeroProps = {
  caseStudies: DatoCmsTemplateCaseStudy[];
};

const CaseStudyHero: FC<HeroProps> = ({ caseStudies }) => {
  const firstCase = caseStudies[0];
  const secondCase = caseStudies[1];
  const caseExcerpt =
    secondCase.excerpt || (secondCase.body && toPlainText(secondCase.body.value)?.substring(0, 97) + '...');

  const domain = process.env.GATSBY_DOMAIN || 'screeningSolution';

  const siteName = {
    screeningSolution: 'S2 Global',
    foamHand: 'S2 | FOAMHAND',
    s2University: 'S2 University',
    raggiX: 'Raggi X',
  } as const;

  type siteNameProps = keyof typeof siteName;

  return (
    <Flex paddingTop={128} flexDirection="column">
      <Container width="100%" md={{ maxWidth: '1008px', marginX: 'auto' }}>
        <Flex flexDirection="column" gap="24px" textAlign="center" marginBottom={48} lg={{ marginBottom: 64 }}>
          <Heading as="h6" fontSize="textMd" textColor="secondaryTurqoise-400" fontWeight="semiBold">
            {domain === 'foamHand' ? 'SUCCESS STORIES' : 'CASE STUDIES'}
          </Heading>
          <Heading
            as="h2"
            textStyle="md"
            textColor="common-white"
            fontWeight="semiBold"
            sm={{ textStyle: 'lg' }}
            lg={{ textStyle: 'xl' }}
          >
            {siteName[domain as siteNameProps]}{' '}
            <Text textColor="secondaryTurqoise-400">{domain === 'foamHand' ? 'Success Stories' : 'Case Studies'}</Text>
          </Heading>
          <Container textColor="gray-50" fontSize="textLg" sm={{ fontSize: 'textXl' }}>
            {`Read all of the latest news and writing from ${siteName[domain as siteNameProps]}.`}
          </Container>
        </Flex>
      </Container>
      <Flex gap="30px" alignItems="center">
        <Container
          width="100%"
          sm={{ flex: '0 0 60%', maxWidth: '60%' }}
          xl={{ flex: '0 0 800px', maxWidth: '800px' }}
          borderRadius="8px"
          position="relative"
          overflowX="hidden"
          overflowY="hidden"
        >
          <Link
            href={`/${domain === 'foamHand' ? 'success-stories' : 'case-studies'}/${firstCase.slug}`}
            textDecoration="none"
          >
            <>
              <Image image={firstCase.featuredImage as ImageData} alt={firstCase.title || ''} width="100%" />
              <Flex
                position="absolute"
                width="100%"
                height="100%"
                left="0"
                top="0"
                flexDirection="column"
                justifyContent="flex-end"
                textColor="common-white"
                gap="24px"
                paddingAll={32}
                css={`
                  &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(359.94deg, #025785 0.23%, rgba(2, 106, 162, 0) 99.96%), url(.jpg),
                      linear-gradient(0deg, #003f96, #003f96),
                      radial-gradient(
                        47.96% 47.96% at 85.55% 72.7%,
                        rgba(53, 171, 225, 0.2) 0%,
                        rgba(66, 180, 228, 0) 100%
                      );
                    opacity: 0.7;
                    z-index: 0;
                  }
                `}
              >
                <Text fontWeight="semiBold" textTransform="uppercase" fontSize="textMd" letterSpacing="pos8" zIndex={1}>
                  {domain === 'foamHand' ? 'SUCCESS STORY' : 'CASE STUDY'}
                </Text>
                <Heading
                  as="h2"
                  textStyle="xs"
                  zIndex={1}
                  sm={{
                    textStyle: 'xxs',
                  }}
                  lg={{
                    textStyle: 'lg',
                  }}
                >
                  {firstCase.title}
                </Heading>
              </Flex>
            </>
          </Link>
        </Container>
        <Link
          href={`/${domain === 'foamHand' ? 'success-stories' : 'case-studies'}/${secondCase.slug}`}
          textDecoration="none"
          display="none"
          sm={{ display: 'flex' }}
        >
          <Flex flexDirection="column" textColor="common-white" gap="24px">
            <Text
              fontWeight="semiBold"
              textTransform="uppercase"
              fontSize="textMd"
              letterSpacing="pos8"
              textColor="secondaryTurqoise-400"
              zIndex={1}
            >
              {domain === 'foamHand' ? 'SUCCESS STORY' : 'CASE STUDY'}
            </Text>
            <Heading
              as="h2"
              textStyle="xs"
              zIndex={1}
              letterSpacing="neg2"
              md={{
                textStyle: 'sm',
              }}
              xl={{
                textStyle: 'md',
              }}
            >
              {secondCase.title}
            </Heading>
            <Container fontSize="textXl" textColor="gray-50" display="none" lg={{ display: 'block' }}>
              {caseExcerpt}
            </Container>
          </Flex>
        </Link>
      </Flex>
    </Flex>
  );
};

export default CaseStudyHero;
