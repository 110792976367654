import { navigate } from 'gatsby';
import { DatoCmsTemplateCaseStudy } from 'graphqlTypes';
import { FC, useEffect, useMemo, useState } from 'react';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Text from 'quarks/Text';

import Select from 'molecules/Select/Select';

import CaseStudyCard from 'components/Cards/CaseStudyCard/CaseStudyCard';
import Pagination from 'components/Pagination/Pagination';

import { toTitleCase } from 'utils/functions';

type BlogListingsType = {
  currentCategory?: string;
  caseStudies: DatoCmsTemplateCaseStudy[];
};

const CaseStudyListings: FC<BlogListingsType> = ({ caseStudies }) => {
  const domain = process.env.GATSBY_DOMAIN || 'screeningSolution';
  const categories: string[] = [];
  caseStudies &&
    caseStudies?.map(item => {
      if (item.category && !categories.includes(item.category)) {
        categories.push(item.category);
      }

      return;
    });
  const [page, setPage] = useState<number>(1);
  const [filterOption, setFilterOption] = useState<string>('');
  const perCount = 12;

  const filtered = useMemo(
    () =>
      filterOption === '' ? caseStudies : caseStudies && caseStudies.filter(blog => blog.category === filterOption),
    [filterOption],
  );

  const goPage = (count: number) => {
    setPage(count);
  };

  const handleChange = (cat: string) => {
    navigate(cat !== '' ? `?tag=${cat.replace(' ', '-')}#blog-listing-container` : '#blog-listing-container');
    setFilterOption(cat);
    goPage(1);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const cat = params.get('tag');
    if (cat && cat !== '') {
      setFilterOption(cat.replace('-', ' '));
    }
  }, []);

  return (
    <Container>
      <Flex flexDirection="column" gap="80px" id="blog-listing-container" lg={{ flexDirection: 'row-reverse' }}>
        {categories.length > 1 && (
          <Flex flexDirection="column" lg={{ flex: '0 0 calc(33.33% - 50px)' }}>
            <Heading as="h5" fontSize="textLg" textColor="gray-900" fontWeight="semiBold" marginBottom={16}>
              {domain === 'foamHand' ? 'Success Story' : 'Case Study'} Categories
            </Heading>
            <Flex
              as="ul"
              flexDirection="column"
              paddingAll={16}
              gap="12px"
              marginBottom={40}
              css="list-style: none"
              display="none"
              lg={{ display: 'flex' }}
            >
              <Text
                textStyle="md"
                as="li"
                paddingLeft={12}
                fontWeight={filterOption === '' ? 'medium' : 'regular'}
                textColor={filterOption === '' ? 'gray-900' : 'gray-500'}
                borderLeft="3px solid"
                borderColor={filterOption === '' ? 'primary-800' : 'common-transparent'}
                cursor="pointer"
                onClick={() => handleChange('')}
              >
                View All
              </Text>
              {categories &&
                categories.map(cat => (
                  <Text
                    textStyle="md"
                    key={cat}
                    as="li"
                    paddingLeft={12}
                    fontWeight={filterOption === cat ? 'medium' : 'regular'}
                    textColor={filterOption === cat ? 'gray-900' : 'gray-500'}
                    borderLeft="3px solid"
                    borderColor={filterOption === cat ? 'primary-800' : 'common-transparent'}
                    cursor="pointer"
                    onClick={() => handleChange(cat)}
                  >
                    {toTitleCase(cat)}
                  </Text>
                ))}
            </Flex>
            <Container lg={{ display: 'none' }} marginBottom={32}>
              <Select list={['View all', ...categories]} onChange={handleChange} width="100%" />
            </Container>
          </Flex>
        )}
        <Container lg={{ flexGrow: 1, flexBasis: 0 }}>
          <Heading textStyle="md" textColor="gray-900" fontWeight="semiBold" letterSpacing="neg2" marginBottom={40}>
            All {domain === 'foamHand' ? 'Success Stories' : 'Case Studies'}
          </Heading>
          <Flex flexDirection="column" flexWrap="wrap" rowGap="56px" columnGap="30px" sm={{ flexDirection: 'row' }}>
            {filtered &&
              filtered.slice((page - 1) * perCount, page * perCount).map((blog: any) => (
                <CaseStudyCard
                  key={blog.id}
                  {...blog}
                  flex="0 0 100%"
                  width="100%"
                  noAuthor={true}
                  sm={{
                    flex: '0 0 calc(50% - 15px)',
                    width: 'calc(50% - 15px)',
                  }}
                  lg={{
                    flex: categories.length > 1 ? '0 0 calc(50% - 15px)' : '0 0 calc(33.3% - 20px)',
                    width: categories.length > 1 ? 'calc(50% - 15px)' : 'calc(33.3% - 20px)',
                  }}
                />
              ))}
          </Flex>
          {filtered && filtered.length > perCount && (
            <Pagination
              total={filtered?.length || 0}
              currentPage={page}
              goPage={goPage}
              countPerpage={perCount}
              toId="blog-listing-container"
              space={120}
            />
          )}
        </Container>
      </Flex>
    </Container>
  );
};

export default CaseStudyListings;
